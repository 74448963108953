import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import Navbar from "./navbar"
import Footer from "./footer"
import "./layout.scss"
import Cart from "./cart"
export const CartContext = React.createContext([]);


const Layout = ({ children }) => {
  const [cart, setCart] = useState([])
  useEffect(() => {
    if (localStorage.getItem('cart')) {
      setCart(JSON.parse(localStorage.getItem('cart')))
    }
  }, [])
  const [_showCart, setShowCart] = useState(false)

  const addToCart = ({productId, size, thick = '0', selectedSize, color = {}, quantity, checkout = false}) => {
    let product = cart.find(res => { return res.productId === productId && res.size === size && res.thick === thick && res.selectedSize === selectedSize })
    if (product) {
      product.quantity = parseInt(product.quantity) + 1
    } else {
      cart.push({ productId: productId, size: size, thick: thick, selectedSize: selectedSize, color:color, quantity: quantity })
    }
    setCart([...cart])
    localStorage.setItem('cart', JSON.stringify(cart))
    if (checkout) {
      navigate("/checkout")
    } else {
      setTimeout(() => {
        showCart()
      }, 100);
    }
  }

  const updateCart = ({ index, quantity }) => {
    if (quantity) {
      cart[index].quantity = quantity
      setCart([...cart])
      localStorage.setItem('cart', JSON.stringify(cart))
    } else {
      let _cart = cart.filter((res, i) => {
        return i !== index
      })
      setCart([..._cart])
      localStorage.setItem('cart', JSON.stringify(_cart))
    }
  }

  const showCart = () => {
    setShowCart(!_showCart)
  }

  return (
    <div className="container-fluid p-0">
      <div className="announcement-bar">
        {/* <span><strong>Year End Sale</strong> is live until 5th January! Use code <strong>YES</strong> for up to <strong>32% OFF</strong></span> */}
        </div>
      <CartContext.Provider value={{ cart, addToCart, showCart, updateCart }}>
        <Navbar />
        <Cart _showCart={_showCart} updateCart={updateCart}></Cart>
        <main>
          {children}
        </main>
        <Footer></Footer>
      </CartContext.Provider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
