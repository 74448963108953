import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import { CartContext } from "./layout"

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "nav-link active" } : { className: "nav-link" }
}

const ExactNavLink = props => (
  <Link getProps={isActive} {...props} />
)

const Navbar = () => {
  const { cart, showCart } = useContext(CartContext)
  const [isClient, setClient ] = useState(false)
  useEffect(() => {
    setClient(true)
  },[])
  let cartCount = 0;
  cart.forEach(item => {
    cartCount = cartCount + parseInt(item.quantity)
  });
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
      <div className="container d-flex">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-navbar"
          aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <Link to="/" className="navbar-brand" href="#"><img src="/logo.png" alt="plumes" /></Link>
        {isClient &&
          <div className="d-flex order-md-last" style={{ cursor: 'pointer', marginTop: '3px' }} onClick={() => showCart()} onKeyDown={() => showCart()} role="presentation">
            <i className="bi bi-cart text-primary" style={{ marginLeft: '20px', fontSize: '24px' }}></i><span className="text-center" style={{ borderRadius: '50%', fontSize: '12px', backgroundColor: '#fff', width: '18px', height: '18px', marginBottom: '24px' }}>{cartCount}</span>
          </div>
        }
        <div className="collapse navbar-collapse" id="main-navbar">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            <li className="nav-item">
              <ExactNavLink
                to="/"
              >
                Home
              </ExactNavLink>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#products" id="products-menu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Products
              </a>
              <ul className="dropdown-menu" aria-labelledby="products-menu">
                <li className="dropdown-item"><ExactNavLink to="/products/all">All Products</ExactNavLink></li>
                <li className="dropdown-item"><ExactNavLink to="/products/pillows">Pillows</ExactNavLink></li>
                <li className="dropdown-item"><ExactNavLink to="/products/mattresses">Mattresses</ExactNavLink></li>
                <li className="dropdown-item"><ExactNavLink to="/products/protectors">Mattress Protectors</ExactNavLink></li>
                <li className="dropdown-item"><ExactNavLink to="/products/eyemasks">Eye Masks</ExactNavLink></li>
                <li className="dropdown-item"><ExactNavLink to="/products/bedspread">Bedspread</ExactNavLink></li>
                <li><button className="dropdown-item"><ExactNavLink to="/products/futon">Futon Mattress</ExactNavLink></button></li>
              </ul>
            </li>
            <li className="nav-item">
              <ExactNavLink
                to="/about"
              >
                Know Us
              </ExactNavLink>
            </li>
            <li className="nav-item">
              <ExactNavLink
                to="/features"
              >
                Why Plumes
              </ExactNavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
